@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --primary: #1e90ff;
    --white: #ffffff;
    --interfont: "Inter", sans-serif;
    --poppinfont: "Poppins", sans-serif;
}
h1 span,h2 span,h3 span,h4 span,h5 span,h6 span{color: var(--primary);}


.custom-toast .Toastify__toast-body {
    font-size: 14px !important;  
  }
  .Toastify__toast-theme--light{
    font-size: 14px !important;  
  }

  /* navbar text */
.logo-text {
  font-size: 2.5rem;
  font-weight: bold;
  color: #1e90ff;
white-space: nowrap;
}

@media (max-width: 768px) {
  .logo-text {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .logo-text {
    font-size: 1.5rem;
  }
}

/* userprofile card */
.user-profile {
  width: 100% !important; 
}


.filedset_border{
  border:1px solid rgba(0, 0, 0, 0.11) !important;
 border-radius: 10px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spin button in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}